.container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.row {
    padding: 5px 0px 5px 0px;
}

.logoRow {
    display: flex;
    justify-content: center;
}

.loginRow {
    align-self: flex-end;
    margin-top: 10px;;
}