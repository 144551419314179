.container {
    border-top: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.link {
    text-decoration: none;
    margin: 0 10px;
    color: #000;
}