@value colors: "../../App.css";
@value appBlue from colors;

.btn {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: appBlue;
    color: #FFF;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}