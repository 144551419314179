.category {
    padding-top: 5px;
}

.name {
    color: #479cc8;
    font-weight: bold;
}

.itemContainer {
    padding-left: 15px;
}

.titleRow { 
    display: flex;
    align-items: center;  
}