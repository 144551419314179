.container {
    padding-top: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    align-items: stretch;
}

.dropdown {      
}

.row {
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nameChi {
    padding-right: 5px;
}

.nameEn {
    padding-right: 5px;
}

.price {
    padding-right: 3px;
}

.unitTxt {
    padding-right: 5px;
}