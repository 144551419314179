@value colors: "../../App.css";
@value appBlue from colors;

.title {
    font-size: 25px;
    font-weight: bold;
}

.fieldLabel {
    padding: 10px 0px;
}

.section {
    padding: 10px 0px;
}

.input {
    height: 25px;
    width: 200px;
}

.dropdown {
    height: 30px;
    width: 200px;
    padding-left: 5px;
}

.textarea {
    width: 200px;
    height: 50px;
}

.shippingFeeRow {
    padding: 3px 0px;    
}

.order {
    padding: 10px 0px;
}

.subTotalItem {
    padding: 5px 0px;
    display: flex;
    align-items: center;
}

.editBtn {
    display: flex; 
    font-size: 20px;
    color: appBlue;
    cursor: pointer;
    margin-left: 3px;
}

.total {
    padding: 5px 0px;
}

.btn {
    background-color: appBlue;    
    border-radius: 5px;
    color: #FFF;
    padding: 5px 10px;
    width: 150px;
    justify-content: center;
    display: flex;
    cursor: pointer;
}