.title  {
    font-size: 20px;
    padding: 5px 0px;
}

.row {
    padding: 5px 0px;
}

.btns {
    margin-top: 10px;
}