.container {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 110px 10px 10px 10px;
}